export const GOOGLE_API_KEY = 'AIzaSyA6xOVLB5HDEyyV2jH0KfbZb4wr3J6xYYU';

export const DISTANCES = [
    { label: '1 km', value: 1 },
    { label: '5 km', value: 5 },
    { label: '10 km', value: 10 },
    { label: '20 km', value: 20 },
    { label: '25 km', value: 25 },
  ];

export const BATCH_SIZE = 25;