import * as msal from '@azure/msal-browser';
import { AUTH_CONF } from './Env';

export interface UserAuthPayload {
  preferredUsername: string;
  accessToken: string;
  idToken: string;
  regionId: number;
  expiration: number;
};

export type UserState = {
  initialized: boolean;
  loggedIn: boolean;
  loading: boolean;
  userAuthPayload?: UserAuthPayload;
  error?: Error;
};

export const msalConfig = {
    auth: {
      clientId: AUTH_CONF.clientId,
      authority: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
            case msal.LogLevel.Info:
              console.info(message);
              return;
            case msal.LogLevel.Verbose:
              console.debug(message);
              return;
            case msal.LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
          }
        },
      },
    },
  };

  export const loginRequest = {
    scopes: AUTH_CONF.scopes // Add other scopes as needed
};

  