import axios, { AxiosInstance } from 'axios';
import { useAuthToken } from '../hooks/authToken'; // Ensure this is the updated version
import { BASE_API_URL } from './Env';

export enum ClientType {
  PLAIN = 'text/plain',
  FORM = 'multipart/form-data',
  JSON = 'application/json',
  PDF = 'application/pdf',
}

export enum AcceptType {
  PDF = 'application/octet-stream',
}

const useRestClient = (
  clientType: ClientType,
  acceptType: AcceptType = AcceptType.PDF
): AxiosInstance => {
  const token = useAuthToken();

  // Create an Axios instance
  const axiosInstance = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      'Content-Type': clientType,
      'Accept': acceptType,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Access-Control-Allow-Origin, Access-Control',
    },
  });

  // Attach token to the headers if available
  axiosInstance.interceptors.request.use(
    (config) => {
      const storedToken = sessionStorage.getItem('authToken'); // Check for token in sessionStorage
      if (storedToken) {
        config.headers.Authorization = `Bearer ${storedToken}`;
      } else if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export default useRestClient;
