import React, { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';
import { loginRequest } from './helpers/authConfig';
import TabsContainer from './pages/TabsContainer';
import useRestClient, { ClientType } from './helpers/AxiosClient';
import './Spinner.css';

const AppContent = () => {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [initialized, setInitialized] = useState(false);
    const [hasAccess, setHasAccess] = useState<boolean | null>(null); // Track user access
    const restClient = useRestClient(ClientType.JSON); // Always returns an Axios instance

    // Initialize MSAL
    useEffect(() => {
        const initializeMSAL = async () => {
            try {
                await instance.initialize();
                setInitialized(true);
            } catch (e) {
                console.error("MSAL initialization error:", e);
            }
        };

        if (!initialized && instance) {
            initializeMSAL();
        }
    }, [instance, initialized]);

    // Ensure user is logged in using loginPopup
    useEffect(() => {
        if (initialized && !isAuthenticated) {
            console.log("User is not authenticated, invoking loginPopup...");
            instance.loginPopup(loginRequest).then(response => {
                console.log("Login successful:", response);
            }).catch(e => {
                if (e.errorCode === "interaction_in_progress") {
                    console.warn("Login already in progress");
                } else {
                    console.error("Failed to login via popup:", e);
                }
            });
        }
    }, [initialized, isAuthenticated, instance]);

    const handleTokenRenewal = async () => {
        // Ensure account is not null, use undefined if no account is present
        const account = accounts.length > 0 ? accounts[0] : undefined;

        if (!account) {
            console.warn("No active account found, cannot renew token.");
            return null;
        }

        const request: SilentRequest = {
            account,
            scopes: loginRequest.scopes,
        };

        try {
            const response = await instance.acquireTokenSilent(request);
            return response.accessToken;
        } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                console.error("Silent token acquisition failed, prompting user login.");
                try {
                    // Fall back to interactive login if silent renewal fails
                    const response = await instance.acquireTokenPopup(request);
                    console.log("Token acquired via popup:", response.accessToken);
                    return response.accessToken;
                } catch (popupError) {
                    console.error("Failed to acquire token via popup:", popupError);
                    return null;
                }
            } else {
                console.error("Token renewal failed:", error);
                return null;
            }
        }
    };

    const setAccess = async () => {
        const response = await restClient.get('/common/geo-mapping-access');

        if (response.data === true) {
            setHasAccess(true);
        } else {
            setHasAccess(false);
        }
    };

    // Check access once after login
    useEffect(() => {
        const checkAccess = async () => {
            if (initialized && isAuthenticated && hasAccess === null) {
                try {
                    await setAccess();
                } catch (error: any) {
                    if (error.response && error.response.status === 401) {
                        sessionStorage.removeItem('authToken'); // Clear token from localStorage
                        const newToken = await handleTokenRenewal();
                        if (newToken) {
                            sessionStorage.setItem('authToken', newToken);

                            await setAccess();
                        }
                    } else {
                        console.error("Failed to check access:", error);
                        setHasAccess(false); 
                    }
                }
            }
        };

        if (initialized && isAuthenticated) {
            checkAccess();
        }
    }, [initialized, isAuthenticated, restClient, hasAccess, handleTokenRenewal, setAccess]);

    // Render content based on access state
    if (!initialized || hasAccess === null) {
        return <div className="spinner"></div>; // Show spinner while loading
    }

    if (isAuthenticated && hasAccess) {
        return (
            <div className="App" style={{ padding: '20px' }}>
                <TabsContainer />
            </div>
        );
    } else {
        return (
            <div className="App" style={{ padding: '20px' }}>
                <p>Sorry, you do not have the necessary permissions to access this app.</p>
            </div>
        );
    }
};

export default AppContent;
