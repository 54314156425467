import React from 'react';
import { FileClaim } from '../helpers/Interfaces';

type Props = {
  marker: FileClaim;
};

const FileInfoWindowComponent: React.FC<Props> = ({ marker }) => {
  return (
    <div className="info-window">
      <div className="info-header d-flex justify-content-between">
        <h4>{marker.name}</h4>
      </div>
      <div className="info-row">
        <label className="info-label">Address: </label> {marker.address}
      </div>
    </div>
  );
};

export default FileInfoWindowComponent;
