import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { AUTH_CONF } from '../helpers/Env';

export const useAuthToken = () => {
    const { instance, accounts } = useMsal();
    const [token, setToken] = useState<string | null>(null);

    useEffect(() => {
        const request = {
            account: accounts[0],
            scopes: AUTH_CONF.scopes,
        };

        const getToken = async () => {
            try {
                // Try to acquire the token silently
                const response = await instance.acquireTokenSilent(request);
                sessionStorage.setItem('authToken', response.accessToken);
                setToken(response.accessToken);
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    try {
                        // Fall back to acquireTokenPopup if silent acquisition fails
                        const response = await instance.acquireTokenPopup(request);
                        
                        // Store the token in sessionStorage
                        sessionStorage.setItem('authToken', response.accessToken);
                        setToken(response.accessToken);
                    } catch (popupError) {
                        console.error('Failed to acquire token via popup:', popupError);
                    }
                } else {
                    console.error('Failed to acquire token silently:', error);
                }
            }
        };

        if (accounts.length > 0) {
            getToken();
        } else {
            console.warn('No accounts found during token acquisition.');
        }
    }, [instance, accounts]);

    return token;
};
