import React, { useState, useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import * as XLSX from 'xlsx';
import Autocomplete from 'react-google-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FaMapMarkerAlt, FaCaretRight, FaFileExcel, FaFilter } from 'react-icons/fa';
import useRestClient, { ClientType } from '../helpers/AxiosClient';
import Map from '../components/Map';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import { GOOGLE_API_KEY, DISTANCES } from '../helpers/Const';
import { Address, Claim, Cat, Region, PM } from '../helpers/Interfaces';

const SearchByAddressTab = () => {
    const [address, setAddress] = useState<Address>();
    const [selectedDistance, setSelectedDistance] = useState('');
    const [markers, setMarkers] = useState<Claim[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCat, setSelectedCat] = useState<string>();
    const [selectedRegion, setSelectedRegion] = useState<string>();
    const [selectedPm, setSelectedPm] = useState<string>();
    const [filterStartDate, setFilterStartDate] = useState<string>('');
    const [filterEndDate, setFilterEndDate] = useState<string>('');
    const [catTags, setCatTags] = useState<Cat[]>([]);
    const [regionList, setRegionList] = useState<Region[]>([]);
    const [pmList, setPmList] = useState<PM[]>([]);
    const [invalidFilter, setInvalidFilter] = useState(false);
    const client = useRestClient(ClientType.JSON);
    const { t } = useTranslation();

    const handleDistanceChange = (event: any) => {
        setSelectedDistance(event.target.value);
    };

    const handlePlaceSelect = (place: any) => {
        const postal = place?.address_components?.find((component: any) => component?.types?.includes('postal_code'));
        setAddress({
            postal: postal?.long_name,
            formattedAddress: place?.formatted_address,
            location: {
                lat: +place?.geometry?.location?.lat(),
                lng: +place?.geometry?.location?.lng()
            }
        });
    };

    const handleExportClick = () => {
        if (markers?.length) {
            // Prepare the data to export
            const formattedData = markers.map(item => ({
                'Claim Number': item.claimNumber,
                'Project Manager': item.pmnameClaim,
                'Adjuster': item.adjuster,
                'Loss Type': item.lossType,
                'Loss Date': item.lossDate,
                'Project Address': item.projAddr,
                'Project City': item.projCity,
                'Project Province': item.projProv,
                'Status': item.status,
                'Inspected': item.isInspected ? "Yes" : "No"  // Convert boolean to readable format
            }));

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(formattedData);

            // Apply bold to the headers
            if(worksheet['!ref']) {
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        font: {
                            bold: true
                        }
                    };
                }
            }
            
            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Export the workbook to Excel file
            XLSX.writeFile(workbook, "ClaimTrakClaims.xlsx");
        }        
    };

    const handleFilterCatChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedCat(event.target.value);
    };

    const handleFilterRegionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedRegion(event.target.value);
        setSelectedPm('');
        fetchPmList(+event.target.value);
    };

    const handleFilterPmChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedPm(event.target.value);
    };

    const fetchCatTags = useCallback(async () => {
        try {
            const response = await client.get('/common/cats');
            setCatTags(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    const fetchRegions = useCallback(async () => {
        try {
            const response = await client.get('/common/regions?isTest=false');
            setRegionList(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    const fetchPmList = useCallback(async (regionId: number) => {
        try {
            const response = await client.get(`/common/project-managers?regionId=${regionId}`);
            setPmList(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    useEffect(() => {
        const handleModalShow = () => {
            fetchCatTags();
            fetchRegions();
        };

        const modal = document.getElementById('filterModal');
        modal?.addEventListener('show.bs.modal', handleModalShow);

        return () => {
            modal?.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, [fetchCatTags, fetchRegions]);

    const applyFilter = async () => {
        if (!address) {
            toast.error(t('errorLocationRequired'));
            return;
        };

        if(!address.postal) {
            toast.error(t('postalIsRequired'));
            return;
        }

        if (!selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        if(!!selectedRegion && !selectedPm) {
            setInvalidFilter(true);
            return;
        }

        setInvalidFilter(false);

        try {
            const queryParameters: string[] = [];
    
            if (filterStartDate) {
                queryParameters.push(`startDate=${filterStartDate}`);
            }
    
            if (filterEndDate) {
                queryParameters.push(`endDate=${filterEndDate}`);
            }
    
            if (selectedCat) {
                queryParameters.push(`cat=${selectedCat}`);
            }

            if (selectedPm) {
                queryParameters.push(`pm=${selectedPm}`);
            }
    
            const queryString = queryParameters.length > 0 ? `&${queryParameters.join('&')}` : '';
            
            setIsLoading(true);

            const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${address?.postal}&latitude=${address?.location?.lat}&longitude=${address?.location?.lng}&distance=${selectedDistance}${queryString}`);

            const claims = response.data;
            setMarkers(claims);

            // Close the modal programmatically
            const modalElement = document.getElementById('filterModal');
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            modalInstance.hide();
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };    

    const clearFilter = async () => {
        setSelectedCat('');
        setSelectedPm('');
        setFilterStartDate('');
        setFilterEndDate('');
        setInvalidFilter(false);

        setIsLoading(true);
        try {
            if(address && address.postal && selectedDistance) 
            {
                const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${address?.postal}&latitude=${address?.location?.lat}&longitude=${address?.location?.lng}&distance=${selectedDistance}`);
                const claims = response.data;
                setMarkers(claims);
            }            
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchClick = useCallback(async (address?: Address) => {
        if(!address?.postal) {
            toast.error(t('postalIsRequired'));
            return;
        }

        if (!selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        if(!address) {
            toast.error(t('addressIsRequired'));
            return;
        }

        setIsLoading(true);
        try {
            const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${address.postal}&latitude=${address.location?.lat}&longitude=${address.location?.lng}&distance=${selectedDistance}`);
            setMarkers(response.data);
        } catch (err) {
            toast.error(`${t('errorFetch')} ${err}`);
        } finally {
            setIsLoading(false);
        }
    }, [client, selectedDistance, t]);

      return (
        <div className="p-3">
            <div className="d-flex align-items-center mb-2">
                <Autocomplete
                    apiKey={GOOGLE_API_KEY}
                    onPlaceSelected={handlePlaceSelect}
                    options={{
                        types: ['address'],
                        componentRestrictions: { country: 'ca' }
                    }}
                    style={{ width: '40%', marginRight: '0.5rem' }}
                    className="form-control"
                />
                <select 
                    value={selectedDistance}
                    onChange={handleDistanceChange}
                    className="form-control" 
                    style={{ marginRight: '0.5rem', width: 'auto' }}>
                    <option value="" disabled hidden>{t('distance')}</option>
                    {DISTANCES.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
                <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                    <FaFilter />
                </button>
                <button className="btn btn-primary" onClick={() => handleSearchClick(address)}>{t('searchBtn')}</button>
                {markers && markers?.length > 0 && <button className="btn btn-primary d-flex align-items-center" onClick={() => handleExportClick()}>
                    <FaFileExcel /> {t('export')}
                </button>}
                {/* Legend */}
                <div className="mb-3">
                <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#748ffb' }}></div>
                        <span className="ms-2">{t('mainAddress')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#40e451' }}></div>
                        <span className="ms-2">{t('fileClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#f47a6a' }}></div>
                        <span className="ms-2">{t('ctClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#9166fb' }}></div>
                        <span className="ms-2">{t('cTInspectedClaims')}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex" style={{ height: 'calc(100vh - 70px)' }}>
                {address && <div style={{ flex: '1 1 20%', paddingRight: '0.5rem' }}>
                    <FaMapMarkerAlt className="text-primary" style={{ minWidth: '20px' }} />
                    <span className="flex-grow-1 mx-2 small">{address.formattedAddress}</span>
                    <FaCaretRight 
                        className="text-secondary" 
                        style={{ minWidth: '20px' }} />
                </div> }
                <div style={{ flex: '3 1 80%' }}>
                    {isLoading ? (
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : address ? (
                        <Map center={address.location} zoom={15} claimMarkers={markers} />
                    ) : (
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src="assets/images/FirstOnsiteLogo.png" alt="No Search" />
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="filterModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="filterModalLabel">
                                {t('filterTitle')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setInvalidFilter(false) }></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="filterStartDate" className="form-label">
                                    {t('startDate')}
                                </label>
                                <input type="date" id="filterStartDate" className="form-control" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                                <label htmlFor="filterEndDate" className="form-label mt-2">
                                    {t('endDate')}
                                </label>
                                <input type="date" id="filterEndDate" className="form-control" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterValue" className="form-label">
                                    {t('filterByCat')}
                                </label>
                                <select id="filterValue" className="form-select" value={selectedCat} onChange={handleFilterCatChange}>
                                    <option value="">{t('selectCatTag')}</option>
                                    {catTags.map((cat, index) => (
                                        <option key={index} value={cat.id}>
                                            {cat.catName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="me-3">
                                    <label htmlFor="filterRegion" className="form-label">
                                        {t('filterByRegion')}
                                    </label>
                                    <select
                                        id="filterRegion"
                                        className="form-select"
                                        value={selectedRegion}
                                        onChange={handleFilterRegionChange}                                    >
                                        <option value="">{t('selectRegion')}</option>
                                        {regionList.map((region, index) => (
                                            <option key={index} value={region.regionId}>
                                                {region.regionName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="filterPm" className="form-label">
                                        {t('filterBypm')}
                                    </label>
                                    <select
                                        id="filterPm"
                                        className="form-select"
                                        value={selectedPm}
                                        onChange={handleFilterPmChange}
                                        disabled={!selectedRegion}
                                    >
                                        <option value="">{t('selectPm')}</option>
                                        {pmList.map((pm, index) => (
                                            <option key={index} value={pm.id}>
                                                {pm.firstName} {pm.lastName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {invalidFilter && <div className="alert alert-warning">{t('pMNotSelected')}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={clearFilter} data-bs-dismiss="modal">
                                {t('clearFilter')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={applyFilter}>
                                {t('applyFilter')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );    
};

export default SearchByAddressTab;
