import React from 'react';
import { Claim } from '../helpers/Interfaces';

type Props = {
  marker: Claim;
};

const InfoWindowComponent: React.FC<Props> = ({ marker }) => {
  return (
    <div className="info-window">
      <div className="info-header d-flex justify-content-between">
        <h4>{marker.claimNumber} ({marker.lossType})</h4>
        <small className="info-date">{marker.lossDate}</small>
      </div>
      <div className="info-row d-flex">
        <i className="fas fa-map-marker-alt text-secondary mr-2"></i>
        <div>{marker.projAddr}</div>
      </div>
      <div className="info-row">
        <label className="info-label">Project Manager: </label> {marker.pmnameClaim}
      </div>
      <div className="info-row">
        <label className="info-label">Property Manager: </label> {marker.propertyManager}
      </div>
      <div className="info-row">
        <label className="info-label">Adjuster: </label> {marker.adjuster}
      </div>
    </div>
  );
};

export default InfoWindowComponent;
