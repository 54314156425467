import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import * as XLSX from 'xlsx';
import { FaMapMarkerAlt, FaCaretRight, FaFilter, FaFileExcel } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import * as bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';
import './SearchByAddressTab.css';
import Map from '../components/Map';
import useRestClient, { ClientType } from '../helpers/AxiosClient';
import { GOOGLE_API_KEY, DISTANCES, BATCH_SIZE } from '../helpers/Const';
import { Claim, FileClaim, Location, ExcelRow, Cat, PM, Region } from '../helpers/Interfaces';

const BulkUploadTab: React.FC = () => {
    const [mainAddress, setMainAddress] = useState<Location | undefined>();
    const [selectedDistance, setSelectedDistance] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [markers, setMarkers] = useState<FileClaim[]>([]);
    const [filteredMarkers, setFilteredMarkers] = useState<FileClaim[]>([]);
    const [paginatedMarkers, setPaginatedMarkers] = useState<FileClaim[]>([]);
    const [claimMarkers, setClaimMarkers] = useState<Claim[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState<FileClaim | undefined>();
    const [showActiveJobs, setShowActiveJobs] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [markerToConfirm, setMarkerToConfirm] = useState<FileClaim | null>(null);
    const [selectedCat, setSelectedCat] = useState<string>();
    const [selectedRegion, setSelectedRegion] = useState<string>();
    const [selectedPm, setSelectedPm] = useState<string>();
    const [filterStartDate, setFilterStartDate] = useState<string>('');
    const [filterEndDate, setFilterEndDate] = useState<string>('');
    const [catTags, setCatTags] = useState<Cat[]>([]);
    const [regionList, setRegionList] = useState<Region[]>([]);
    const [pmList, setPmList] = useState<PM[]>([]);
    const [invalidFilter, setInvalidFilter] = useState(false);
    const client = useRestClient(ClientType.JSON);
    const { t } = useTranslation();

    const processExcelData = (data: string): { address: string; name: string }[] => {
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet: ExcelRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 }) as ExcelRow[];

        const headerRowIndex = worksheet.findIndex(row => row.includes('ADDRESS') && row.includes('TOWN'));

        if (headerRowIndex === -1) {
            throw new Error(t('errorHeadersNotFound'));
        }

        const addressColIndex = worksheet[headerRowIndex].indexOf('ADDRESS');
        const townColIndex = worksheet[headerRowIndex].indexOf('TOWN');
        const nameIndex = worksheet[headerRowIndex].indexOf('NAME');

        const addresses = worksheet.slice(headerRowIndex + 1).map(row => ({
            address: `${row[addressColIndex]} ${row[townColIndex]}`,
            name: row[nameIndex]
        }));
        return addresses;
    };

    const geocodeAddress = async (address: string): Promise<Location | null> => {
        const apiKey = GOOGLE_API_KEY;
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`);
            const location = response.data.results[0]?.geometry?.location;
            const postal = response.data.results[0]?.address_components?.find((component: any) => component?.types?.includes('postal_code'))?.long_name;
            if (location) {
                return { address, lat: location.lat, lng: location.lng, postal };
            }
        } catch (error) {
            toast.error(`${t('errorGeocoding')} ${address}: ${error}`);
        }
        return null;
    };

    const calculateDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLng = (lng2 - lng1) * (Math.PI / 180);
        const a = 
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const handleFileProcessed = async (data: string) => {
        const addresses = processExcelData(data);
        const geocodedAddresses = await Promise.all(addresses.map(async addr => {
            const location = await geocodeAddress(addr.address);
            if (location) {
                return { ...location, name: addr.name };
            }
            return null;
        }));
        const validMarkers = geocodedAddresses.filter(marker => marker !== null) as FileClaim[];

        if (validMarkers.length > 0) {
            const main = validMarkers[0];
            setMainAddress(main);

            validMarkers.sort((a, b) => {
                const distanceA = calculateDistance(main.lat, main.lng, a.lat, a.lng);
                const distanceB = calculateDistance(main.lat, main.lng, b.lat, b.lng);
                return distanceA - distanceB;
            });
        }

        setMainAddress(validMarkers[0]);
        setMarkers(validMarkers);
        setIsLoading(false);
        setCurrentPage(1);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setFileUploaded(false);
        setSelectedDistance('');

        const file = e.target.files?.[0];
        if (file) {
            const fileType = file.type;
            if (fileType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType === "application/vnd.ms-excel") {
                setFileUploaded(true); 
                const reader = new FileReader();
                reader.onload = (event) => {
                    const data = event.target?.result as string;
                    handleFileProcessed(data);
                };
                reader.readAsBinaryString(file);
            } else {
                setIsLoading(false);
                toast.error(t('errorInvalidFileType'));
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleDistanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedDistance(event.target.value);
    };

    const handleExportClick = () => {
        if (claimMarkers?.length) {
            // Prepare the data to export
            const formattedData = claimMarkers.map(item => ({
                'Claim Number': item.claimNumber,
                'Project Manager': item.pmnameClaim,
                'Adjuster': item.adjuster,
                'Loss Type': item.lossType,
                'Loss Date': item.lossDate,
                'Project Address': item.projAddr,
                'Project City': item.projCity,
                'Project Province': item.projProv,
                'Status': item.status,
                'Inspected': item.isInspected ? "Yes" : "No"  // Convert boolean to readable format
            }));

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(formattedData);

            // Apply bold to the headers
            if(worksheet['!ref']) {
                const range = XLSX.utils.decode_range(worksheet['!ref']);
                for (let C = range.s.c; C <= range.e.c; ++C) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        font: {
                            bold: true
                        }
                    };
                }
            }
            
            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Export the workbook to Excel file
            XLSX.writeFile(workbook, "ClaimTrakClaims.xlsx");
        }        
    };

    const handleSearchClick = useCallback(async () => {
        if (!fileUploaded) {
            toast.error(t('fileIsRequired'));
            return;
        }
        if (!selectedDistance) {
            toast.error(t('distanceIsRequired'));
            return;
        }

        setIsLoading(true);
        try {
            const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${mainAddress?.postal}&latitude=${mainAddress?.lat}&longitude=${mainAddress?.lng}&distance=${selectedDistance}`);

            const claims = response.data;
            setClaimMarkers(claims);

            // Highlight items in paginatedMarkers that are also in claimMarkers
            const updatedMarkers = markers.map(marker => ({
                ...marker,
                isHighlighted: claims.some(claim => `${claim.projAddr} ${claim.projCity}` === marker.address)
            }));
            setMarkers(updatedMarkers);
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    }, [client, selectedDistance, mainAddress, fileUploaded, t, markers, setMarkers]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowActiveJobs(event.target.checked);
    };

    const handleItemClick = (marker: FileClaim) => {
        setSelectedLocation(marker);
    };

    const handleConfirmMainAddress = () => {
        if (markerToConfirm) {
            setMainAddress(markerToConfirm);
            const updatedMarkers = markers.filter(marker => marker !== markerToConfirm);
            updatedMarkers.unshift(markerToConfirm);
            setMarkers(updatedMarkers);
            setPaginatedMarkers(updatedMarkers.slice(0, BATCH_SIZE));
            setMarkerToConfirm(null);
        }
    };

    const handleFilterCatChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedCat(event.target.value);
    };

    const handleFilterRegionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedRegion(event.target.value);
        setSelectedPm('');
        fetchPmList(+event.target.value);
    };

    const handleFilterPmChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setSelectedPm(event.target.value);
    };

    const fetchCatTags = useCallback(async () => {
        try {
            const response = await client.get('/common/cats');
            setCatTags(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    const fetchRegions = useCallback(async () => {
        try {
            const response = await client.get('/common/regions?isTest=false');
            setRegionList(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    const fetchPmList = useCallback(async (regionId: number) => {
        try {
            const response = await client.get(`/common/project-managers?regionId=${regionId}`);
            setPmList(response.data);
        } catch (error) {
            toast.error(t('errorFetchCats'));
        }
    }, [client, t]);

    useEffect(() => {
        const handleModalShow = () => {
            fetchCatTags();
            fetchRegions();
        };

        const modal = document.getElementById('filterModal');
        modal?.addEventListener('show.bs.modal', handleModalShow);

        return () => {
            modal?.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, [fetchCatTags, fetchRegions]);

    const applyFilter = async () => {
        if (!mainAddress) {
            toast.error(t('errorMainAddressRequired'));
            return;
        };

        if(!!selectedRegion && !selectedPm) {
            setInvalidFilter(true);
            return;
        }

        setInvalidFilter(false);

        try {
            const queryParameters: string[] = [];
    
            if (filterStartDate) {
                queryParameters.push(`startDate=${filterStartDate}`);
            }
    
            if (filterEndDate) {
                queryParameters.push(`endDate=${filterEndDate}`);
            }
    
            if (selectedCat) {
                queryParameters.push(`cat=${selectedCat}`);
            }

            if (selectedPm) {
                queryParameters.push(`pm=${selectedPm}`);
            }
    
            const queryString = queryParameters.length > 0 ? `&${queryParameters.join('&')}` : '';
            
            setIsLoading(true);

            const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${mainAddress?.postal}&latitude=${mainAddress?.lat}&longitude=${mainAddress?.lng}&distance=${selectedDistance}${queryString}`);

            const claims = response.data;
            setClaimMarkers(claims);
    
            // Highlight items in paginatedMarkers that are also in claimMarkers
            const updatedPaginatedMarkers = paginatedMarkers.map(marker => ({
                ...marker,
                isHighlighted: claims.some(claim => claim.lossAddressLat === marker.lat && claim.lossAddressLon === marker.lng)
            }));
            setPaginatedMarkers(updatedPaginatedMarkers);

            // Close the modal programmatically
            const modalElement = document.getElementById('filterModal');
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            modalInstance.hide();
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };    

    const clearFilter = async () => {
        setSelectedCat('');
        setSelectedPm('');
        setFilterStartDate('');
        setFilterEndDate('');
        setInvalidFilter(false);

        setIsLoading(true);
        try {
            const response: AxiosResponse<Claim[]> = await client.get(`/common/claims/search/address?postal=${mainAddress?.postal}&latitude=${mainAddress?.lat}&longitude=${mainAddress?.lng}&distance=${selectedDistance}`);
            const claims = response.data;
            setClaimMarkers(claims);

            const updatedMarkers = markers.map(marker => ({
                ...marker,
                isHighlighted: claims.some(claim => `${claim.projAddr} ${claim.projCity}` === marker.address)
            }));
            setMarkers(updatedMarkers);
        } catch (err) {
            toast.error(t('errorFetch'));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (mainAddress && selectedDistance) {
            const filtered = markers.filter(marker => {
                const distance = calculateDistance(mainAddress.lat, mainAddress.lng, marker.lat, marker.lng);
                return distance <= Number(selectedDistance);
            });
            setFilteredMarkers(filtered);
            setCurrentPage(1);
        } else {
            setFilteredMarkers(markers);
        }
    }, [mainAddress, selectedDistance, markers]);
    
    useEffect(() => {
        const paginated = filteredMarkers.slice((currentPage - 1) * BATCH_SIZE, currentPage * BATCH_SIZE);
        setPaginatedMarkers(paginated);
    }, [currentPage, filteredMarkers]);

    const totalPages = Math.ceil(filteredMarkers.length / BATCH_SIZE);

    return (
        <div className="p-3">
            <div className="d-flex align-items-center mb-2">
                <input
                    type="file"
                    className="form-control"
                    style={{ width: '40%', marginRight: '0.5rem' }}
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    onClick={(event: any)=> { 
                        event.target.value = null;
                   }}
                />
                <select
                    value={selectedDistance}
                    onChange={handleDistanceChange}
                    className="form-control"
                    style={{ marginRight: '0.5rem', width: 'auto' }}
                >
                    <option value="">
                        {t('distance')}
                    </option>
                    {DISTANCES.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className="form-check ml-3" style={{ marginRight: '0.5rem' }}>
                    <label className="form-check-label" htmlFor="showActiveJobs">
                        {t('chkActiveJobs')}
                    </label>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="showActiveJobs"
                        checked={showActiveJobs}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <button className="btn" type="button" data-bs-toggle="modal" data-bs-target="#filterModal">
                    <FaFilter />
                </button>
                <button className="btn btn-primary" onClick={() => handleSearchClick()}>
                    {t('searchBtn')}
                </button>
                {showActiveJobs && claimMarkers && claimMarkers?.length > 0 && <button className="btn btn-primary d-flex align-items-center" onClick={() => handleExportClick()}>
                    <FaFileExcel /> {t('export')}
                </button>}
                {/* Legend */}
                <div className="mb-3">
                <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#748ffb' }}></div>
                        <span className="ms-2">{t('mainAddress')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#40e451' }}></div>
                        <span className="ms-2">{t('fileClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#f47a6a' }}></div>
                        <span className="ms-2">{t('ctClaims')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="legend-box" style={{ backgroundColor: '#9166fb' }}></div>
                        <span className="ms-2">{t('cTInspectedClaims')}</span>
                    </div>
                </div>
            </div>            
            <div className="d-flex" style={{ height: 'calc(100vh - 70px)' }}>
                <div
                    style={{
                        flex: '1 1 20%',
                        paddingRight: '0.5rem',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 100px)',
                    }}
                >
                    {fileUploaded && <div className="alert alert-info" role="alert">
                        <small>{t('infoMessage')}</small>
                    </div>}
                    <nav>
                        <ul className="pagination">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button onClick={() => handlePageChange(page)} className="page-link">
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="list-group">
                        {paginatedMarkers.map((marker, index) => (
                            <div
                                key={index}
                                className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center ${
                                    marker.isHighlighted ? 'bg-warning text-dark' : ''
                                }`}
                                onClick={() => handleItemClick(marker)}
                            >
                                <FaMapMarkerAlt className={ marker === mainAddress ? 'text-primary' : 'text-success' } style={{ minWidth: '20px' }} />
                                <span className="flex-grow-1 mx-2 small">{marker.address}</span>
                                <FaCaretRight
                                    className="text-secondary"
                                    style={{ minWidth: '20px', cursor: 'pointer' }}
                                    onClick={() => setMarkerToConfirm(marker)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirmModal"
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ flex: '3 1 80%' }}>
                    {isLoading ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    ) : mainAddress ? (
                        <Map
                            zoom={15}
                            fileMarkers={paginatedMarkers}
                            center={mainAddress}
                            selectedLocation={selectedLocation}
                            claimMarkers={showActiveJobs ? claimMarkers : undefined}
                        />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src="assets/images/FirstOnsiteLogo.png" alt="No Search" />
                        </div>
                    )}
                </div>
            </div>

            <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="confirmModalLabel">
                                {t('confirmTitle')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {t('confirmMessage')} {selectedLocation?.address}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                {t('cancel')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleConfirmMainAddress} data-bs-dismiss="modal">
                                {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="filterModal" tabIndex={-1} aria-labelledby="filterModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="filterModalLabel">
                                {t('filterTitle')}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setInvalidFilter(false) }></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="filterStartDate" className="form-label">
                                    {t('startDate')}
                                </label>
                                <input type="date" id="filterStartDate" className="form-control" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} />
                                <label htmlFor="filterEndDate" className="form-label mt-2">
                                    {t('endDate')}
                                </label>
                                <input type="date" id="filterEndDate" className="form-control" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="filterValue" className="form-label">
                                    {t('filterByCat')}
                                </label>
                                <select id="filterValue" className="form-select" value={selectedCat} onChange={handleFilterCatChange}>
                                    <option value="">{t('selectCatTag')}</option>
                                    {catTags.map((cat, index) => (
                                        <option key={index} value={cat.id}>
                                            {cat.catName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 d-flex align-items-center">
                                <div className="me-3">
                                    <label htmlFor="filterRegion" className="form-label">
                                        {t('filterByRegion')}
                                    </label>
                                    <select
                                        id="filterRegion"
                                        className="form-select"
                                        value={selectedRegion}
                                        onChange={handleFilterRegionChange}                                    >
                                        <option value="">{t('selectRegion')}</option>
                                        {regionList.map((region, index) => (
                                            <option key={index} value={region.regionId}>
                                                {region.regionName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="filterPm" className="form-label">
                                        {t('filterBypm')}
                                    </label>
                                    <select
                                        id="filterPm"
                                        className="form-select"
                                        value={selectedPm}
                                        onChange={handleFilterPmChange}
                                        disabled={!selectedRegion}
                                    >
                                        <option value="">{t('selectPm')}</option>
                                        {pmList.map((pm, index) => (
                                            <option key={index} value={pm.id}>
                                                {pm.firstName} {pm.lastName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {invalidFilter && <div className="alert alert-warning">{t('pMNotSelected')}</div>}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={clearFilter} data-bs-dismiss="modal">
                                {t('clearFilter')}
                            </button>
                            <button type="button" className="btn btn-primary" onClick={applyFilter}>
                                {t('applyFilter')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </div>
    );
};

export default BulkUploadTab;
