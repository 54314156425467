const isProd = process.env.REACT_APP_ENV === 'prod';
const isStaging = process.env.REACT_APP_ENV === 'staging';

let BASE_API_URL_LET = '';

if (isProd) {
  BASE_API_URL_LET = 'https://ca-geo-mapping-api.azurewebsites.net'; // TODO: setup on azure
} else if (isStaging) {
  BASE_API_URL_LET = 'https://ca-geo-mapping-api-staging.azurewebsites.net'; // TODO: setup on azure
} else {
  BASE_API_URL_LET = 'https://dev-ca-geo-mapping-api.azurewebsites.net';
}

export const BASE_API_URL = BASE_API_URL_LET;

export const AUTH_CONF = isProd
  ? {
    clientId: '0c38a793-73c0-4c70-9863-0af0ddeb2192',
    tenant: '9f175ec5-875a-4cc2-b995-e65d79bb421e',
    domainHint: 'https://login.microsoftonline.com/',
    authorityUrl: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
    scopes: ['api://795b3f9d-08d4-4265-89a7-4635770882fd/.default'],
    authorityTypeString: 'AAD',
  }
  : {
    clientId: '301a7273-7dcc-4e06-8f8f-e184538b5275',
    tenant: '9f175ec5-875a-4cc2-b995-e65d79bb421e',
    domainHint: 'https://login.microsoftonline.com/',
    authorityUrl: 'https://login.microsoftonline.com/9f175ec5-875a-4cc2-b995-e65d79bb421e',
    scopes: ['api://84548be3-62bb-463b-a73d-6a214bc09578/.default'],
    authorityTypeString: 'AAD',
  };