import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SearchByAddressTab from './SearchByAddressTab';
import BulkUploadTab from './BulkUploadTab';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tabs/style/react-tabs.css';
import './TabsContainer.css';

const AddressTabs = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Tabs>
        <TabList className="nav nav-tabs">
          <Tab className="nav-item">
            <span className="nav-link">{t('addressTab')}</span>
          </Tab>
          <Tab className="nav-item">
            <span className="nav-link">{t('uploadTab')}</span>
          </Tab>
        </TabList>

        <TabPanel>
          <SearchByAddressTab />
        </TabPanel>

        <TabPanel>
          <BulkUploadTab />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AddressTabs;
