import React, { useState, useEffect } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './helpers/authConfig';
import AppContent from './AppContent';

function App() {
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();

    useEffect(() => {
        const instance = new PublicClientApplication(msalConfig);
        setMsalInstance(instance);
    }, []);

    if (!msalInstance) {
        return <div>Loading MSAL...</div>;
    }

    return (
        <MsalProvider instance={msalInstance}>
            <AppContent />
        </MsalProvider>
    );
}

export default App;
